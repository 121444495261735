body {
    background: #fafafa;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    color: #333;
}

/* ==========================================================================
Hide ng-cloak on page load, https://docs.angularjs.org/api/ng/directive/ngCloak
========================================================================== */
[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
    display: none !important;
}

/* ==========================================================================
Development Ribbon
========================================================================== */
.ribbon {
    background-color: #a00;
    box-shadow: 0 0 10px #888;
    left: -3.5em;
    moz-box-shadow: 0 0 10px #888;
    moz-transform: rotate(-45deg);
    ms-transform: rotate(-45deg);
    o-transform: rotate(-45deg);
    overflow: hidden;
    position: absolute;
    top: 40px;
    transform: rotate(-45deg);
    webkit-box-shadow: 0 0 10px #888;
    webkit-transform: rotate(-45deg);
    white-space: nowrap;
    width: 15em;
    z-index: 9999;
    pointer-events: none;
}

.ribbon a {
    border: 1px solid #faa;
    color: #fff;
    display: block;
    font: bold 81.25% 'Helvetica Neue', Helvetica, Arial, sans-serif;
    margin: 1px 0;
    padding: 10px 50px;
    text-align: center;
    text-decoration: none;
    text-shadow: 0 0 5px #444;
    pointer-events: none;
}

/* ==========================================================================
Version number in navbar
========================================================================== */
.navbar-version {
    font-size: 10px;
    color: #ccc
}

/* ==========================================================================
Browser Upgrade Prompt
========================================================================== */
.browserupgrade {
    margin: 0.2em 0;
    background: #ccc;
    color: #000;
    padding: 0.2em 0;
}

/* ==========================================================================
Logo styles
========================================================================== */
.navbar-brand.logo {
    padding: 5px 15px;
}

.logo .logo-img {
    height: 45px;
    display: inline-block;
}

/* ==========================================================================
Main page styles
========================================================================== */
.hero-unit {
    margin: 50px auto 0 auto;
    width: 300px;
    font-size: 18px;
    font-weight: 200;
    line-height: 30px;
    background-color: #eee;
    border-radius: 6px;
    padding: 60px;
}

.hero-unit h1 {
    font-size: 60px;
    line-height: 1;
    letter-spacing: -1px;
}

.hipster {
    display: inline-block;
    width: 347px;
    height: 497px;
    background: url("../images/hipster.png") no-repeat center top;
    background-size: contain;
}

/* wait autoprefixer update to allow simple generation of high pixel density media query */
@media
only screen and (-webkit-min-device-pixel-ratio: 2),
only screen and (   min--moz-device-pixel-ratio: 2),
only screen and (     -o-min-device-pixel-ratio: 2/1),
only screen and (        min-device-pixel-ratio: 2),
only screen and (                min-resolution: 192dpi),
only screen and (                min-resolution: 2dppx) {
    .hipster {
        background: url("../images/hipster2x.png") no-repeat center top;
        background-size: contain;
    }
}

/* ==========================================================================
Generic styles
========================================================================== */
.error {
    color: white;
    background-color: red;
}

.pad {
    padding: 10px;
}

.break {
    white-space: normal;
    word-break:break-all;
}

.voffset  { margin-top: 2px; }
.voffset1 { margin-top: 5px; }
.voffset2 { margin-top: 10px; }
.voffset3 { margin-top: 15px; }
.voffset4 { margin-top: 30px; }
.voffset5 { margin-top: 40px; }
.voffset6 { margin-top: 60px; }
.voffset7 { margin-top: 80px; }
.voffset8 { margin-top: 100px; }
.voffset9 { margin-top: 150px; }

.readonly {
    background-color: #eee;
    opacity: 1;
}

/* ==========================================================================
make sure browsers use the pointer cursor for anchors, even with no href
========================================================================== */
a:hover, .hand, [jh-sort-by] {
    cursor: pointer;
}

/* ==========================================================================
Metrics and Health styles
========================================================================== */
#threadDump .popover, #healthCheck .popover {
    top: inherit;
    display: block;
    font-size: 10px;
    max-width: 1024px;
}

#healthCheck .popover {
    margin-left: -50px;
}

.health-details {
    min-width: 400px;
}

/* ==========================================================================
start Password strength bar style
========================================================================== */
ul#strengthBar {
    display:inline;
    list-style:none;
    margin:0;
    margin-left:15px;
    padding:0;
    vertical-align:2px;
}

.point:last {
    margin:0 !important;
}
.point {
    background:#DDD;
    border-radius:2px;
    display:inline-block;
    height:5px;
    margin-right:1px;
    width:20px;
}

/* ==========================================================================
Custom alerts for notification
========================================================================== */
.alerts .alert{
    text-overflow: ellipsis;
}
.alert pre{
    background: none;
    border: none;
    font: inherit;
    color: inherit;
    padding: 0;
    margin: 0;
}

.alert .popover pre {
    font-size: 10px;
}

.alerts .toast {
    position: fixed;
    width: 100%;
}

.alerts .toast.left {
    left: 5px;
}

.alerts .toast.right {
    right: 5px;
}

.alerts .toast.top {
    top: 55px;
}

.alerts .toast.bottom {
    bottom: 55px;
}

@media screen and (min-width: 480px) {
    .alerts .toast {
        width: 50%;
    }
}

/* ==========================================================================
entity tables helpers
========================================================================== */
/* Remove Bootstrap padding from the element
   http://stackoverflow.com/questions/19562903/remove-padding-from-columns-in-bootstrap-3 */
.no-padding-left { padding-left: 0 !important; }
.no-padding-right { padding-right: 0 !important; }
.no-padding-top { padding-top: 0 !important; }
.no-padding-bottom { padding-bottom: 0 !important; }
.no-padding { padding: 0 !important; }

/* bootstrap 3 input-group 100% width
   http://stackoverflow.com/questions/23436430/bootstrap-3-input-group-100-width */
.width-min { width: 1% !important; }

/* Makes toolbar not wrap on smaller screens
 http://www.sketchingwithcss.com/samplechapter/cheatsheet.html#right */
.flex-btn-group-container {
   display: -webkit-flex;
   display: flex;
   -webkit-flex-direction: row;
   flex-direction: row;
   -webkit-justify-content: flex-end;
   justify-content: flex-end;
}

.jh-table > tbody > tr > td {
    /* Align text in td vertically (top aligned by Bootstrap) */
    vertical-align: middle;
}

.jh-table > thead > tr > th > .glyphicon-sort, .jh-table > thead > tr > th > .glyphicon-sort-by-attributes, .jh-table > thead > tr > th > .glyphicon-sort-by-attributes-alt {
    /* less visible sorting icons */
    opacity: 0.5;
}

.jh-table > thead > tr > th > .glyphicon-sort:hover, .jh-table > thead > tr > th > .glyphicon-sort-by-attributes:hover, .jh-table > thead > tr > th > .glyphicon-sort-by-attributes-alt:hover {
    /* full visible sorting icons and pointer when mouse is over them */
    opacity: 1;
    cursor: pointer;
}

/* ==========================================================================
entity detail page css
========================================================================== */
.dl-horizontal.jh-entity-details > dd {
    margin-bottom: 15px;
}

@media screen and (min-width: 768px) {
    .dl-horizontal.jh-entity-details > dt {
        margin-bottom: 15px;
    }

    .dl-horizontal.jh-entity-details > dd {
        border-bottom: 1px solid #eee;
        padding-left: 180px;
        margin-left: 0;
    }
}

/* ==========================================================================
ui bootstrap tweaks
========================================================================== */
.nav, .pagination, .carousel, .panel-title a {
    cursor: pointer;
}

.datetime-picker-dropdown > li.date-picker-menu div > table .btn-default,
.uib-datepicker-popup  > li > div.uib-datepicker > table .btn-default {
    border: 0;
}

.datetime-picker-dropdown > li.date-picker-menu div > table:focus,
.uib-datepicker-popup  > li > div.uib-datepicker > table:focus {
    outline: none;
}



/* jhipster-needle-css-add-main JHipster will add new css style */

.fm-navbar {
    background-color: #ff8f32;
}

.fm-navbar > li > a {
    color: white;
}

.navbar-default {
    background-color: #ff8f32;
    border-color: #e24802;
}
.navbar-default .navbar-brand {
    color: #ecf0f1;
}
.navbar-default .navbar-brand:hover,
.navbar-default .navbar-brand:focus {
    color: #ecdbff;
}
.navbar-default .navbar-text {
    color: #ecf0f1;
}
.navbar-default .navbar-nav > li > a {
    color: #ecf0f1;
}
.navbar-default .navbar-nav > li > a:hover,
.navbar-default .navbar-nav > li > a:focus {
    color: #ecdbff;
}
.navbar-default .navbar-nav > .active > a,
.navbar-default .navbar-nav > .active > a:hover,
.navbar-default .navbar-nav > .active > a:focus {
    color: #ecdbff;
    background-color: #e24703;
}
.navbar-default .navbar-nav > .open > a,
.navbar-default .navbar-nav > .open > a:hover,
.navbar-default .navbar-nav > .open > a:focus {
    color: #ecdbff;
    background-color: #e24703;
}
.navbar-default .navbar-toggle {
    border-color: #e24703;
}
.navbar-default .navbar-toggle:hover,
.navbar-default .navbar-toggle:focus {
    background-color: #e24703;
}
.navbar-default .navbar-toggle .icon-bar {
    background-color: #ecf0f1;
}
.navbar-default .navbar-collapse,
.navbar-default .navbar-form {
    border-color: #ecf0f1;
}
.navbar-default .navbar-link {
    color: #ecf0f1;
}
.navbar-default .navbar-link:hover {
    color: #ecdbff;
}

.seap-form-container {
    background-color: #eeeeee;
    padding-top: 130px;
    padding-bottom: 20px;
    padding-left: 25px;
    padding-right: 25px;
    height: 100%;
    min-height: 100vh;
}

div.seap-form-container div.well {
    background-color: #e0d8d2;
}

div.seap-form-container button.btn-basic {
    background-color: #e0d8d2;
}

div.seap-form-container div.panel-default div.panel-heading {
    background-color: #e0d8d2;
}

.seap-form-div {
    padding: 0px 10px;
}

div.seap-form-container table.table-striped , th, td {
    border: 1px solid #e0d8d2;
    margin-bottom: 0px;
}

th {
    background: -webkit-linear-gradient(#e6e6e6, #f0f0f0, #e6e6e6); /* For Safari 5.1 to 6.0 */
    background: -o-linear-gradient(#e6e6e6, #f0f0f0, #e6e6e6); /* For Opera 11.1 to 12.0 */
    background: -moz-linear-gradient(#e6e6e6, #f0f0f0, #e6e6e6); /* For Firefox 3.6 to 15 */
    background: linear-gradient(#e6e6e6, #f0f0f0, #e6e6e6); /* Standard syntax (must be last)*/
}

button.btn-warning {
    background-color: #ff8f32;
}

div.seap-form-container .text-warning {
    color: #ff8f32;
}

.bg-warning {
    background-color: #ff8f32;
    color: white;
}

.text-warning {
    color: #ff8f32;
}

.seap-control-icon {
    cursor: pointer;
    cursor: hand;
}

div.seap-form-container .btn-basic, .btn-default {
    color: #333 !important;
}

div.seap-form-container .btn-default {
    color: #333 !important;
}

.btn-circle {
    width: 30px;
    height: 30px;
    text-align: center;
    padding: 6px 8px !important;
    font-size: 12px;
    line-height: 1.428571429;
    border-radius: 15px !important;
}
.btn-circle.btn-lg {
    width: 50px;
    height: 50px;
    padding: 10px 16px;
    font-size: 18px;
    line-height: 1.33;
    border-radius: 25px;
}
.btn-circle.btn-xl {
    width: 70px;
    height: 70px;
    padding: 10px 16px;
    font-size: 24px;
    line-height: 1.33;
    border-radius: 35px;
}

.sp-caption-label {
    margin-bottom: 0px;
    margin-top: 10px;
}

/***************************** Dropzone Styling *****************************/

/**
 * The dnd-list should always have a min-height,
 * otherwise you can't drop to it once it's empty
 */
.simpleDemo ul[dnd-list] {
    min-height: 42px;
    padding-left: 0px;
}

/**
 * The dndDraggingSource class will be applied to
 * the source element of a drag operation. It makes
 * sense to hide it to give the user the feeling
 * that he's actually moving it.
 */
.simpleDemo ul[dnd-list] .dndDraggingSource {
    display: none;
}

/**
 * An element with .dndPlaceholder class will be
 * added to the dnd-list while the user is dragging
 * over it.
 */
.simpleDemo ul[dnd-list] .dndPlaceholder {
    background-color: #ddd;
    display: block;
    min-height: 42px;
}

.simpleDemo ul[dnd-list] li {
    background-color: #fff;
    border: 1px solid #ddd;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    display: block;
    padding: 10px 15px;
    margin-bottom: -1px;
    cursor: pointer;
    cursor: hand;
}

/**
 * Show selected elements in green
 */
.simpleDemo ul[dnd-list] li.selected {
    background-color: #dff0d8;
    color: #3c763d;
}
/* DEMO  ------------------------------------- End*/
.sp-btn-14 {
    font-size: 14px !important;
}

.toggle-on , .toggle-off {
    font-size: 14px !important;
}

.seap-ctrl-disabled {
    cursor: not-allowed !important;
    opacity: 0.5;
}

/* IE fieldset problem*/
fieldset {
    /* to set absolute position for :after content */
    position: relative;
}
/* this will 'screen' all fieldset content from clicks */
fieldset[disabled]:after {
    content: ' ';
    position: absolute;
    z-index: 1;
    top: 0; right: 0; bottom: 0; left: 0;
    /* i don't know... it was necessary to set background */
    background: url( data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==);
}

.seap-back-btn {
    position: absolute;
    top: -43px;
    right: 30px;
    z-index: 1200;
}

.seap-menu-btn-group {
    position: fixed;
    top: 86px;
    right: 20px;
    z-index: 1004;
}

@media print
{
    .no-print, .no-print *
    {
        display: none !important;
    }
}

.fi-filter-box {
    font-size: 11px;
}

.fi-filter-box .form-control {
    padding: 3px 10px;
    font-size: 11px;
    height: 26px;
}

.fi-filter-box .btn {
    padding: 3px 10px;
    font-size: 11px;
    height: 26px;
}

.fi-filter-box .toggle.btn {
    padding: 3px 10px;
    font-size: 11px;
    height: 26px !important;
    min-height: 26px !important;
}

.fi-filter-box .toggle-off {
    padding: 5px 10px 5px 20px;
    font-size: 11px !important;
}

.fi-filter-box .toggle-on {
    padding: 5px 20px 5px 10px;
    font-size: 11px !important;
}

.uib-timepicker .btn-link {
    color: #337ab7 !important;
}

.seap-flag-ico {
    cursor: help;
}

.st-sort-ascent:before{
    content: '\25B2';
}

.st-sort-descent:before{
    content: '\25BC';
}

.toast {
    opacity: 1 !important;
}

@media screen and (max-device-width: 1280px) {

    #footer {
        position: relative !important;

    }
}

#footer {
    bottom:0;
    width:100%;
}

.body-login {
    height: 100VH;
    padding-top: 40px;
    padding-bottom: 40px;
    background-color: #f5f5f5;
    background: url(../images/sicap/image4.jpg) no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

@keyframes spinner {
    0% {
        transform: rotateZ(0deg);
    }

    100% {
        transform: rotateZ(359deg);
    }
}

* {
    box-sizing: border-box;
}

.login-container2 {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    padding: 20px;
    transition: all 0.25s ease-in;
}

.login {
    border-radius: 2px 2px 5px 5px;
    padding: 10px 20px 20px 20px;
    width: 30%;
    min-width: 250px;
    background: #ffffff;
    position: relative;
    padding-bottom: 80px;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.3);
    transition: all 0.25s ease-in;
}

.login-wide {
    border-radius: 2px 2px 5px 5px;
    padding: 10px 20px 20px 20px;
    width: 90%;
    max-width: 700px;
    background: #ffffff;
    position: relative;
    padding-bottom: 80px;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.3);
    transition: all 0.25s ease-in;
}

.login.loading .bigbutton {
    max-height: 100%;
    padding-top: 50px;
    text-align: center;
}

.login.loading .bigbutton .spinner {
    opacity: 1;
    top: 40%;
}

.login.ok button {
    background-color: #489537 !important;
}

.login.notok button {
    background-color: #d73d32;
}

.login.ok .bigbutton .spinner {
    border-radius: 0;
    border-top-color: transparent;
    border-right-color: transparent;
    height: 20px;
    animation: none;
    transform: rotateZ(-45deg);
}

.login.notok .bigbutton .spinner {
    border-radius: 0;
    border-top-color: transparent;
    border-right-color: transparent;
    border-left-color: transparent;
    border-bottom-color: transparent;
    animation: none;
    box-shadow: none !important;
    top: 30% !important;
    text-align: center;
}

.login.notok .bigbutton .spinner:after {
    font-size: 70px;
    width: 100%;
    vertical-align: middle;
    content: "\00d7";
    text-shadow: 0px 1px 5px rgba(0, 0, 0, 0.3);
}

.login-wide.loading .bigbutton {
    max-height: 100%;
    padding-top: 50px;
    text-align: center;
}

.login-wide.loading .bigbutton .spinner {
    opacity: 1;
    top: 40%;
}

.login-wide.ok button {
    background-color: #489537 !important;
}

.login-wide.notok button {
    background-color: #d73d32;
}

.login-wide.ok .bigbutton .spinner {
    border-radius: 0;
    border-top-color: transparent;
    border-right-color: transparent;
    height: 20px;
    animation: none;
    transform: rotateZ(-45deg);
}

.login-wide.notok .bigbutton .spinner {
    border-radius: 0;
    border-top-color: transparent;
    border-right-color: transparent;
    border-left-color: transparent;
    border-bottom-color: transparent;
    animation: none;
    box-shadow: none !important;
    top: 30% !important;
    text-align: center;
}

.login-wide.notok .bigbutton .spinner:after {
    font-size: 70px;
    width: 100%;
    vertical-align: middle;
    content: "\00d7";
    text-shadow: 0px 1px 5px rgba(0, 0, 0, 0.3);
}

.login input {
    display: block;
    padding: 10px 10px;
    margin-bottom: 10px;
    width: 100%;
    border: 1px solid #ddd;
    transition: border-width 0.2s ease;
    border-radius: 2px;
    color: #ccc;

}

.login input + i.fa {
    color: #fff;
    font-size: 1em;
    position: absolute;
    margin-top: -35px;
    margin-left: -1px;
    opacity: 0;
    left: 0;
    transition: all 0.1s ease-in;
}

.login input:focus {
    outline: none;
    color: #444;
    border-color: #3669AF;
    border-left-width: 35px;
}

.login input:focus + i.fa {
    opacity: 1;
    left: 30px;
    transition: all 0.25s ease-out;
}

.login a {
    font-size: 0.8em;
    color: #3669AF;
    text-decoration: none;
}

.login .title {
    color: #444;
    font-size: 1.2em;
    font-weight: 600;
    margin: 10px 0 30px 0 !important;
    border-bottom: 1px solid #eee;
    padding-bottom: 20px;
}

.login .title2 {
    color: #444;
    font-size: 1.2em;
    font-weight: 600;
    margin: 10px 0 -5px 0 !important;
    border-bottom: 1px solid #eee;
    padding-bottom: 20px;
}

.login .bigbutton {
    width: 100%;
    height: 100%;
    padding: 10px 10px;
    background: #3669AF;
    color: #fff;
    display: block;
    border: none;
    margin-top: 20px;
    position: absolute;
    left: 0;
    bottom: 0;
    max-height: 60px;
    border: 0px solid rgba(0, 0, 0, 0.1);
    border-radius: 0 0 2px 2px;
    transform: rotateZ(0deg);
    transition: all 0.1s ease-out;
    border-bottom-width: 7px;
}

.login .bigbutton .spinner {
    display: block;
    width: 40px;
    height: 40px;
    position: absolute;
    border: 4px solid #ffffff;
    border-top-color: rgba(255, 255, 255, 0.3);
    border-radius: 100%;
    left: 50%;
    top: 0;
    opacity: 0;
    margin-left: -20px;
    margin-top: -20px;
    animation: spinner 0.6s infinite linear;
    box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.2);
}

.login:not(.loading) .bigbutton:hover {
    box-shadow: 0px 1px 3px #3669AF;
}

.login:not(.loading) .bigbutton:focus {
    border-bottom-width: 4px;
}

.widget {
    margin: 3px 0 0 0 !important;
    padding: 0;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    margin: 3px 0 30px 0;
}

.widget.transparent .widget-header {
    border-bottom: 1px solid #cccccc;
}

.widget.transparent .widget-header, .widget.transparent .widget-body {
    background-color: rgba(0, 0, 0, 0);
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

.widget-header {
    min-height: 15px !important;
    position: relative;
    min-height: 35px;
    background: #D3CECA;
    -webkit-box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
    color: #555555;
    padding-left: 12px;
    text-align: right;
}

.widget-body {
    margin-bottom: 15px;
    -webkit-box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
    background-color: #fbfbfb;
    padding: 12px;
}

.center-block-custom {
    left: 40%;
    right: 40%;
    width: 360px;
}

@media screen and (max-width: 550px) {
    .navbar .navbar-inner {
        background: url('../images/sicap/banner-mobile.png') repeat-x !important;
    }
}

@media (min-width: 768px) {
    .row.equal {
        display: flex;
        flex-wrap: wrap;
    }
}

.equal {
    display: flex !important;
    display: -webkit-flex !important;
    flex-wrap: wrap !important;
}

body {
    background-color: #eeeeee !important;
    background-color: white;
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    line-height: 1.45;
}

html {
    font-size: 1em;
}

p {
    margin-bottom: 1.3em;
}

h1, h2, h3, h4 {
    margin-top: 10px !important;
    font-weight: inherit;
}

h1 {
    margin-top: 0;
    font-size: 3.157em;
}

h2 {
    font-size: 2.369em;
}

h3 {
    font-size: 1.777em;
}

h4 {
    font-size: 1.333em;
}

small, .font_small {
    font-size: 0.75em;
}

.themeprimary {
    color: #ff8f32 !important;
}

.themeprimary-bk {
    background-color: #ff8f32 !important;
}

.orange-bk {
    background-color: #ec7c2c !important;
}

.red-bk {
    background-color: #cf2a27 !important;
}

.blue-bk {
    background-color: #085394 !important;
}

.themefourthcolor {
    color: #e75b8d !important;
}

.navbar .navbar-inner {
    background: url('../images/sicap/banner.png') repeat-x;
}

.navbar {
    padding: 0;
    margin: 0;
    border: none;
    height: 125px;
    z-index: 200;
}

.navbar .navbar-inner {
    border: 0;
    margin: 0;
    padding-left: 0;
    padding-right: 0;
    min-height: 120px;
    position: relative;
}

.navbar .navbar-inner .navbar-header .navbar-account {
    height: 85px;
}

.navbar .navbar-inner .navbar-header .navbar-account > div {
    display: inline-block;
    width: auto;
    vertical-align: top;
}

.navbar .navbar-inner .navbar-header .languages {
    position: absolute;
    top: 88px;
    right: -50px;
    zoom: 1;
    list-style: none;
    margin-bottom: 0;
    display: inline-block;
}

.navbar .navbar-inner .navbar-header .languages > li {
    float: left;
    position: relative;
    font-size: 16px;
    cursor: pointer;
}

.navbar .navbar-inner .navbar-header .languages > li > a {
    position: relative;
    display: inline-block;
    color: white;
    cursor: pointer;
}

.navbar .navbar-inner .navbar-header .navbar-account .connected {
    position: absolute;
    top: 40px;
    right: 30px;
    zoom: 1;
    list-style: none;
    margin-bottom: 0;
    display: inline-block;
}

.navbar .navbar-inner .navbar-header .navbar-account .connected > li {
    float: left;
    position: relative;
    cursor: default;
}

.navbar .navbar-inner .navbar-header .navbar-account .connected > li > a {
    position: relative;
    display: inline-block;
    color: white;
    cursor: default;
}

.navbar .navbar-inner .navbar-header .navbar-account .connected > li > a:hover {
    text-decoration: underline;
    cursor: pointer;
}

.navbar .navbar-inner .navbar-header .navbar-account .login-area {
    margin-top: 50px;
    margin-right: 30px;
    height: 45px;
    opacity: 1;
    position: relative;
    cursor: pointer;
}

.navbar .navbar-inner .navbar-header .navbar-account .login-link {
    position: absolute;
    right: 30px;
    top: 30px;
    cursor: pointer;
}

.navbar .navbar-inner .navbar-header .navbar-account .setting a {
    position: relative;
    width: 50px;
    height: 45px;
    display: inline-block;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.navbar .navbar-inner .navbar-header .navbar-account .setting a:hover {
    text-decoration: none;
}

.navbar .navbar-inner .navbar-header .navbar-account .setting a .icon {
    display: block;
    color: #ffffff;
    font-size: 22px;
    line-height: 45px;
    text-align: center;
}

.navbar .navbar-inner .navbar-header .navbar-account .setting-container {
    -webkit-transform: rotateY(90deg);
    -moz-transform: rotateY(90deg);
    -o-transform: rotateY(90deg);
    -ms-transform: rotateY(90deg);
    transform: rotateY(90deg);
    -webkit-transform-origin: top left;
    -moz-transform-origin: top left;
    -o-transform-origin: top left;
    -ms-transform-origin: top left;
    transform-origin: top left;
    display: inline-block;
    width: 505px;
    height: 45px;
    zoom: 1;
    filter: alpha(opacity=0);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
    text-align: center;
    padding: 11px 0;
    position: absolute;
    top: 0;
    right: 0;
}

.navbar .navbar-inner .navbar-header .navbar-account .setting-container input[type=checkbox] {
    display: inline-block;
}

.navbar .navbar-inner .navbar-header .navbar-account .setting-container input[type=checkbox] + .text {
    color: #ffffff;
    margin-right: 8px;
    font-weight: 300;
}

.navbar .navbar-inner .navbar-header .navbar-account .setting-container input[type=checkbox] + .text:before {
    border-color: #2dc3e8;
}

.navbar .navbar-inner .navbar-header .navbar-account .setting-container input[type=checkbox]:checked + .text {
    color: #ffffff;
}

.navbar .navbar-inner .navbar-header .navbar-account .setting-container input[type=checkbox]:checked + .text:before {
    border-color: #2dc3e8;
    color: #fb6e52;
}

.navbar .navbar-inner .navbar-header .navbar-account .account-area,
.navbar .navbar-inner .navbar-header .navbar-account .setting-container,
.navbar .navbar-inner .navbar-header .navbar-account .setting {
    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    -o-transform-style: preserve-3d;
    -ms-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -o-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transition: 0.9s;
    -moz-transition: 0.9s;
    -o-transition: 0.9s;
    transition: 0.9s;
}

.navbar .navbar-inner .navbar-header .navbar-account.setting-open .setting {
    right: 505px;
    background-color: #2dc3e8;
}

.navbar .navbar-inner .navbar-header .navbar-account.setting-open .setting a {
    -webkit-box-shadow: 0 -6px 40px rgba(0, 0, 0, 0.4);
    -moz-box-shadow: 0 -6px 40px rgba(0, 0, 0, 0.4);
    box-shadow: 0 -6px 40px rgba(0, 0, 0, 0.4);
}

.navbar .navbar-inner .navbar-header .navbar-account.setting-open .setting a:before {
    content: '';
    display: inline-block;
    right: 0;
    position: absolute;
    width: 50px;
    background-color: #ffffff;
    border: 0;
    top: 0;
    height: 3px;
}

.navbar .navbar-inner .navbar-header .navbar-account.setting-open .account-area {
    -webkit-transform: rotateY(-90deg);
    -moz-transform: rotateY(-90deg);
    -o-transform: rotateY(-90deg);
    -ms-transform: rotateY(-90deg);
    transform: rotateY(-90deg);
    -webkit-transform-origin: top right;
    -moz-transform-origin: top right;
    -o-transform-origin: top right;
    -ms-transform-origin: top right;
    transform-origin: top right;
    zoom: 1;
    filter: alpha(opacity=0);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
    right: 450px;
}

.navbar .navbar-inner .navbar-header .navbar-account.setting-open .setting-container {
    -webkit-transform: rotateY(0deg);
    -moz-transform: rotateY(0deg);
    -o-transform: rotateY(0deg);
    -ms-transform: rotateY(0deg);
    transform: rotateY(0deg);
    zoom: 1;
    filter: alpha(opacity=100);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
    padding: 11px;
}

.navbar .navbar-login {
    margin-top: 60px;
    margin-right: 10px;
}

.navbar .navbar-brand {
    margin-left: 20px;
    margin-top: 3px;
}

.navbar .sidebar-collapse {
    text-align: center;
    padding: 0;
    position: absolute;
    left: 110px;
    top: 85px;
    z-index: 2;
}

.navbar .sidebar-collapse .collapse-icon {
    display: inline-block;
    cursor: pointer;
    font-size: 18px;
    color: #ffffff;
    padding: 11px 1px;
    line-height: 5px;
    position: relative;
}

.navbar .sidebar-collapse.active:hover {
    box-shadow: none;
}

.navbar .sidebar-collapse.active .collapse-icon {
    font-size: 14px;
    -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    padding: 11px;
    width: 42px;
}

.navbar.navbar-fixed-top {
    z-index: 1003;
}

.navbar.navbar-fixed-top + .main-container {
    margin-top: 120px;
}

.navbar .nav-logo {
    position: absolute;
    left: 22px;
    top: 10px;
    width: 69px;
    height: 100px;
    background: url(../images/sicap/logo.png) no-repeat;
}

.navbar .nav-text-caps {
    position: absolute;
    left: 115px;
    top: 15px;
    width: 102px;
    height: 50px;
    background: url(../images/sicap/text-caps.png) no-repeat;
}

.navbar .nav-text {
    position: absolute;
    left: 245px;
    top: 25px;
    width: 138px;
    height: 37px;
    background: url(../images/sicap/text.png) no-repeat;
}
.navbar .nav-text-only {
    position: absolute;
    left: 245px;
    top: 33px;
    width: 190px;
    font-size: 13px;
    font-weight: 700;
    height: 37px;
    color: #f5811f !important;
    font-family: inherit;
}

.navbar-vertical-left {
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    width: 160px;
    overflow: hidden;
    border: none;
    border-radius: 0;
    margin: 0;
    padding-top: 120px;
    z-index: 3;
}

.navbar-vertical-left ul.navbar-nav {
    width: 100%;
    height: 100%;
    display: table;
    margin: 0;
    border-left: 5px solid #f3f3f3;
}

.navbar-vertical-left ul.navbar-nav:before,
.navbar-vertical-left ul.navbar-nav:after {
    display: none;
}

.navbar-vertical-left ul.navbar-nav > li {
    display: grid;
    float: none;
}

.navbar-vertical-left ul.navbar-nav > li > a {
    display: table-cell;
    vertical-align: middle;
}

.navbar-vertical-left ul.navbar-nav > li > a > i.fa {
    margin-right: 20px;
}

.navbar-vertical-left {
    background-color: #ffffff;
    -webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    color: #262626;
}

.navbar-vertical-left ul.navbar-nav > li > a {
    color: #262626;
    border-bottom: 1px solid #f3f3f3;
}

.navbar-vertical-left {
    -webkit-transition: -webkit-transform .3s;
    -moz-transition: -moz-transform .3s;
    transition: transform .3s;
    -webkit-transform: translateX(-100px);
    -moz-transform: translateX(-100px);
    transform: translateX(-100px);
}

.navbar-vertical-left:hover {
    -webkit-transform: translateX(0px);
    -moz-transform: translateX(0px);
    transform: translateX(0px);
}

.navbar-vertical-left ul.navbar-nav {
    -webkit-transition: -webkit-transform .3s;
    -moz-transition: -moz-transform .3s;
    transition: transform .3s;
    -webkit-transform: translateX(100px);
    -moz-transform: translateX(100px);
    transform: translateX(100px);
}

.navbar-vertical-left:hover ul.navbar-nav {
    -webkit-transform: translateX(0px);
    -moz-transform: translateX(0px);
    transform: translateX(0px);
}

.navbar-actions {
    position: absolute;
    right: 28px;
    bottom: 5px;
}

.main-container {
    padding-top: 195px;
    padding-left: 80px;
    padding-right: 25px;
}

.nfo-box {
    display: block;
    width: 100%;
    height: 100%;
    color: white;
    cursor: pointer;
}

.nfo-box-content {
    min-height: 85px;
    border-radius: 4px;
    padding: 10px 0px;
    font-size: 12px;
}

.nfo-box > .nfo-box-content > span {
    margin-top: 0;
    font-size: 22px;
    padding: 10px;
}

.nfo-box > .nfo-box-content > i {
    margin-top: 0;
    font-size: 22px;
}

.nfo-box > .nfo-box-content > p {
    text-transform: uppercase;
    margin: 0;
    padding-left: 20px;
    padding-right: 20px;
}

.u-items-list {
    -webkit-box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
}

.u-items-list .u-items-list__header {
    text-transform: uppercase;
    color: #ff8f32;
    background: #edede4;
    border-bottom: 1px solid #edede4;
    line-height: 10px;
    font-size: 12px;
    font-weight: 600;
    height: 37px;
    line-height: 37px;
}

.u-items-list .u-items-list__content .u-items-list__item {
    background: #fff;
    border-bottom: 1px solid #EDEDE4;
    padding: 15px 15px 15px 0px;
}

.pull-left {
    float: left !important;
}

.pull-right {
    float: right !important;
}

.padding-bottom-10 {
    padding-bottom: 10px !important;
}

.padding-bottom-15 {
    padding-bottom: 15px !important;
}

.padding-top-10 {
    padding-bottom: 10px !important;
}

.padding-top-15 {
    padding-bottom: 15px !important;
}

.padding-left-10 {
    padding-left: 10px !important;
}

.padding-right-15 {
    padding-right: 15px !important;
}

.padding-left-10 {
    padding-left: 10px !important;
}

.padding-left-15 {
    padding-left: 15px !important;
}

.margin-bottom-10 {
    margin-bottom: 10px !important;
}

.margin-bottom-15 {
    margin-bottom: 15px !important;
}

.margin-top-10 {
    margin-bottom: 10px !important;
}

.margin-top-15 {
    margin-bottom: 15px !important;
}

.margin-left-10 {
    margin-left: 10px !important;
}

.margin-right-15 {
    margin-right: 15px !important;
}

.margin-left-10 {
    margin-left: 10px !important;
}

.margin-left-15 {
    margin-left: 15px !important;
}

.btn-xs {
    font-size: 11px;
    padding: 2px 7px;
}

.btn-xs > .fa,
.btn-xs > .typcn,
.btn-xs > .glyphicon,
.btn-xs > [class*="wi-"] {
    font-size: 12px;
    margin-right: 2px;
}

.btn-xs > .fa.right,
.btn-xs > .typcn.right,
.btn-xs > .glyphicon.right,
.btn-xs > [class*="wi-"].right {
    margin-right: 0;
    margin-left: 2px;
}

.btn-xs.icon-only {
    width: 24px;
    height: 24px;
    padding: 2px 6px;
}

.btn-xs.icon-only > .fa,
.btn-xs.icon-only > .typcn,
.btn-xs.icon-only > .glyphicon,
.btn-xs.icon-only > [class*="wi-"] {
    font-size: 12px;
    line-height: 7px;
}

.btn-orange-xs {
    background-color: #F5811F !important;
    position: relative;
}

.btn-green-xs {
    background-color: green !important;
    position: relative;
}

.btn-attention {
    background-color: white !important;
    position: relative;
    color:#F5811F!important;
    border:1px solid #F5811F!important;
}

.btn-default-xs {
    color: #444444 !important;
    background-color: #f9f9f9 !important;
    border-color: #cccccc !important;
    position: relative;
}

.btn {
    cursor: pointer;
    vertical-align: middle;
    margin: 0;
    display: inline-block;
    color: #ffffff;
    -webkit-box-shadow: 0 1px 0 rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 0 1px 0 rgba(0, 0, 0, 0.05);
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.05);
    -webkit-transition: all 0.15s ease;
    -moz-transition: all 0.15s ease;
    -o-transition: all 0.15s ease;
    transition: all 0.15s ease;
    -webkit-border-radius: 2px;
    -webkit-background-clip: padding-box;
    -moz-border-radius: 2px;
    -moz-background-clip: padding;
    border-radius: 2px;
    background-clip: padding-box;
    font-size: 13px;
}

.btn:hover,
.btn:focus {
    color: #ffffff;
}

.shutter-out-vertical-ca-no-focus, .shutter-out-vertical-ca {
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -moz-osx-font-smoothing: grayscale;
    position: relative;
    background: #e1e1e1;
    -webkit-transition-property: color;
    transition-property: color;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
}

.shutter-out-vertical-ca-no-focus:before, .shutter-out-vertical-ca:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: #ffffff;
    color: #ff8f32;
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
    -webkit-transform-origin: 50%;
    transform-origin: 50%;
    -webkit-transition-property: transform;
    transition-property: transform;
    -webkit-transition-duration: 0.1s;
    transition-duration: 0.1s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
}

.shutter-out-vertical-ca:hover, .shutter-out-vertical-ca:focus, .shutter-out-vertical-ca:active {
    color: #ff8f32 !important;
}

.shutter-out-vertical-ca-no-focus:hover, .shutter-out-vertical-ca-no-focus:hover:before {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    color: #ff8f32 !important;
}

.shutter-out-vertical-ca:hover:before, .shutter-out-vertical-ca:focus:before, .shutter-out-vertical-ca:active:before {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
}

.shutter-out-vertical-df {
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -moz-osx-font-smoothing: grayscale;
    position: relative;
    background: #ffffff;
    -webkit-transition-property: color;
    transition-property: color;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
}

.shutter-out-vertical-df:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: #ffffff;
    color: #444444;
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
    -webkit-transform-origin: 50%;
    transform-origin: 50%;
    -webkit-transition-property: transform;
    transition-property: transform;
    -webkit-transition-duration: 0.1s;
    transition-duration: 0.1s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
}

.shutter-out-vertical-df:hover, .shutter-out-vertical-df:focus, .shutter-out-vertical-df:active {
    color: #444444 !important;
}

.shutter-out-vertical-df:hover:before, .shutter-out-vertical-df:focus:before, .shutter-out-vertical-df:active:before {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
}

.shutter-out-vertical-attention {
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -moz-osx-font-smoothing: grayscale;
    position: relative;
    background: #ffffff;
    -webkit-transition-property: color;
    transition-property: color;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
}

.shutter-out-vertical-attention:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: #ffffff;
    color: #444444;
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
    -webkit-transform-origin: 50%;
    transform-origin: 50%;
    -webkit-transition-property: transform;
    transition-property: transform;
    -webkit-transition-duration: 0.1s;
    transition-duration: 0.1s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
}

.shutter-out-vertical-attention:hover, .shutter-out-vertical-attention:focus, .shutter-out-vertical-attention:active {
    color: #333 !important;
    background: white !important;
    border:1px solid #333!important;
}

.shutter-out-vertical-df:hover:before, .shutter-out-vertical-df:focus:before, .shutter-out-vertical-df:active:before {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
}

.margin-0 {
    margin: 0 !important;
}

.title-entity {
    color: #ff8f32;
    font-size: 20px;
    font-weight: 400;
}

.u-items-list .u-items-list__content .u-items-list__item .u-items-list__item__properties {
    color: #444444;
    font-size: 12px;
    font-weight: 400;
}

.block {
    display: block !important;
}

.text-align-left {
    text-align: left !important;
}

.text-align-right {
    text-align: right !important;
}

.margin-left-5 {
    margin-left: 5px !important;
}

.value-title-entity {
    font-size: 20px;
    font-weight: 400;
}

.page-header {
    margin: 0;
    padding-bottom: 1px;
    padding-left: 12px;
    background-color: #ededed;
    z-index: 0;
    min-height: 55px;
    border: 0 !important;
    -webkit-box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
}

.page-header.page-header-fixed {
    position: fixed;
    right: 0;
    left: 0;
    top: 118px;
    z-index: 2;
    margin-top: 0 !important;
    background-color: rgba(250, 250, 250, 1);
}

.page-header .header-buttons {
    height: auto;
    position: relative;
    display: block;
    right: unset;
    top: unset;
    padding: 13px 15px 13px 0;
}

.widget-body {
    min-height: 50px;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    background: #ffffff;
    margin-bottom: 15px;
    -webkit-box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
    background-color: #fbfbfb;
    padding: 12px;
}

/*start radio checkbox css*/
@keyframes ripple {
    0% {
        box-shadow: 0px 0px 0px 1px transparent;
    }

    50% {
        box-shadow: 0px 0px 0px 15px rgba(0, 0, 0, 0.1);
    }

    100% {
        box-shadow: 0px 0px 0px 15px transparent;
    }
}

.sc-radio {
    margin: 16px 0;
}

.sc-radio.sc-radio-inline {
    display: inline-block;
}

.sc-radio input[type="radio"] {
    display: none;
}

.sc-radio input[type="radio"]:checked + label:before {
    border-color: #ff8f32;
    animation: ripple 0.2s linear forwards;
}

.sc-radio input[type="radio"]:checked + label:after {
    transform: scale(1);
}

.sc-radio label {
    display: inline-block;
    height: 20px;
    position: relative;
    padding: 0 30px;
    margin-bottom: 0;
    cursor: pointer;
    vertical-align: bottom;
}

.sc-radio label:before, .sc-radio label:after {
    position: absolute;
    content: '';
    border-radius: 50%;
    transition: all .3s ease;
    transition-property: transform, border-color;
}

.sc-radio label:before {
    left: 0;
    top: 0;
    width: 20px;
    height: 20px;
    border: 2px solid rgba(0, 0, 0, 0.54);
}

.sc-radio label:after {
    top: 5px;
    left: 5px;
    width: 10px;
    height: 10px;
    transform: scale(0);
    background: #ff8f32;
}

/*end radio checkbox css*/

.databox-wrapper {
    position: relative;
    padding-bottom: 10px;
}

.databox-wrapper .databox-container {
    /*valorile fixe pentru accest tip de buton sunt comentate in functie de cum se doreste.*/
    /*width: 550px;*/
    width: 100%;
    display: table;
    border: 1px solid #ff8f32;
}

.databox-view {
    border:1px solid green!important;
}

.databox-view.databox-container .databox-right {
    background-color: green!important;
    border-left: 1px solid green!important;
}

.databox-wrapper .databox-container .databox-left {
    /*width: 250px;*/
    width: 80%;
    color: #262626;
    font-size: 14px;
    padding: 20px;
    display: table-cell;
}

.databox-wrapper .databox-container .databox-left :nth-child(1) {
    font-size: 16px;
    display: block;
}

.databox-wrapper .databox-container .databox-left :nth-child(2) {
    font-size: 14px;
}

.databox-wrapper .databox-container .databox-right {
    text-align: center;
    cursor: pointer;
    display: table-cell;
    /*width: 150px;*/
    width: 20%;
    color: white;
    font-size: 14px;
    padding: 20px;
    vertical-align: middle;
    background-color: #ff8f32;
    border-left: 1px solid #ff8f32;
}

.databox-container-nfo {
    position: relative;
    width: 100%;
    display: table;
    border: 1px solid #ff8f32;
}

.databox-container-nfo .databox-container-header {
    background-color: #ff8f32;
    color: white;
    padding: 15px;
}

.databox-container-nfo .databox-container-content {
    padding: 10px;
}

.full-w-mod {
    width: 100% !important;
    min-height: 50px;
    font-size: 14px;
    text-align: left;
    padding: 10px;
}

toggle.disabled {
    cursor: not-allowed;
    pointer-events: none;
    box-shadow: none;
    opacity: 0.65;
}

.toggle-width-180 {
    width: 180px;
}


/*start modal*/
.modal {
    text-align: center;
    padding: 0!important;
}

.modal:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    margin-right: -4px;
}

.modal-dialog {
    display: inline-block;
    text-align: left;
    vertical-align: middle;
}

/*end modal*/
